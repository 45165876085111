<template>
    <div class="xwzx">
     <section class='trbaner'>
        <div class="foxj">
            <div class='am-container'>
                <div class='txt'>
                    <div class="am-vertical-align-middle">
                     <p class="tit">新闻中心</p>
                </div>
                </div>
            </div>
        </div>
        <img src='../assets/banner/banner5.png'>
    </section> 
    <section class="nyjbox">
        <div class="am-container">
            <el-row :gutter="10">
                <a  v-for="item in dataList " :key="item.id" @click="func1(item)">
                <el-col :span="4"  > 
                <i>{{item.add_time}}</i>
                </el-col>
                <el-col :span="14"> 
                <span>
                    <h1>{{item.article_name}}</h1>
                    <h2>{{item.article_blurb}}</h2>
                    <h3>详情</h3>
                </span>
                </el-col>
                <el-col :span="6" class="xwimg"> 
                <img :src="/zhcwaterweb/+item.furl">
                </el-col>
                </a>
            </el-row>    
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                class="pagination"
                :page-size="9"
                layout=" prev, pager, next"
                :total="this.total">
                </el-pagination>
        </div> 
    </section>    
    </div>
</template>
<script>
import {
  getListxw,
} from "api/xw";
export default {
    data() {
        return {
            dataList:[],
            //列表数据
            modelForm:[],
            data:[],
            searchInfo:{
                pageNo: 1,
                pageSize: 8,
                order_by:'add_time desc'
            },
            total:1

        }
    },
    methods: {
        getList() {
        this.isShowloading = true;
        getListxw(this.searchInfo).then((res) => {
        this.total = res.data.entity.all_count
        this.dataList = res.data.list;
        let a = this.dataList
        for(var i =0;i<a.length;i++){
            this.dataList[i].add_time = this.dataList[i].add_time.substr(0,10)
        }
        
      });},
      func(item) {
        this.$router.push({name: 'solution1',params:{ id:item.fuid}});
      },
             handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.searchInfo.page_no = val
            this.getList()
         },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.searchInfo.page_no = val
            this.getList()

        },
      func1(item1) {
            let pathInfo = this.$router.resolve({
                path: '/Journalism1',
                query: {
                    id: item1.fuid 
                }
            })
            window.open(pathInfo.href,'_blank')
        // this.$router.push({name: 'Journalism1',params:{ id:item1.fuid}});
      },
    },
    mounted() {
    this.getList()
}
}
</script>
<style lang="scss" scoped>
.xwzx{
    background-color: #F5F5F5;
}
.trbaner {
    position: relative;
}
.trbaner img {
    max-width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    border: 0;
}
.trbaner .foxj {
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 22;
}
.am-container {
    position: relative;
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    max-width: 1230px;
}
.foxj .txt {
    position: absolute;
    text-align: left;
    left: 1.5rem;
    height: 100%;
    display: table-cell;
    width: 46%;
}
.foxj .txt .tit{
    font-size: 32px;
    margin-bottom: 15px;
    color: #fff;
}
.foxj .note{
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 2;
    color: #fff;
}
.am-container::after{
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.foxj .txt .am-vertical-align-middle {
       position: relative;
       top: 30%;
}
.am-path {
    height: 50px;
    .el-breadcrumb {
    border: 1px solid #eee;
    font-size: 14px;
    line-height: 50px;
    height: 50px;
    padding-left: 20px;
}}
.nyjbox{
    text-align: left;
    margin-top: 20px;
    a{
    display: block;
    border-bottom: 2px solid #d7d8db;
    padding: 30px 0;
    overflow: hidden;
    i{
    display: inline-block;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    color: #0053ad;
    font-family: 'DINCond';
    text-align: left;
    vertical-align: top;
    }
    .xwimg{
        overflow: hidden; 
    }
    img{
    width: 100%;
    height: auto;
    transition: all 0.6s;
    max-height: 200px;
    }
    h1{
        color: #444;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
    font-weight: bold;
    }
    h2{
        font-size: 14px;
    line-height: 22px;
    color: #999;
    }
    h3{
            display: inline-block;
    padding-right: 22px;
    font-size: 14px;
    line-height: 18px;
    color: #999;
    margin: 20px 0 0 0;
    }
    }
    a:hover {
        border-bottom:  2px solid #0053ad;;
        h1{
        color: #0053ad;
        }
        h3{
        color: #0053ad;
        }
        h2{
            color: #666;
        }
        img{
            transform: scale(1.4); 
        }
    }
}
.pagination{
      justify-content: center;
      display: flex;
}
</style>
